<template>
  <footer
    class="mx-auto px-4 py-6 bg-slate-800 flex justify-between border-t-2 w-full"
  >
    <div class="flex-col items-center justify-center mx-auto space-y-4">
      <div class=" flex gap-4 text-slate-400 items-center justify-center">
        <NuxtLink to="/">Home</NuxtLink>
        <NuxtLink to="/property">Property</NuxtLink>
      </div>
      <div>
        <p class="text-xs text-slate-500">©2024 ISUKJADI All rights reserved | email : <a href="mailto:dicky@dickyjiang.com">si DIKKI</a> atau <a href="mailto:budi@avasoft.com">si BUDI</a> </p>
      </div>
      
    </div>
  </footer>
</template>

<script setup></script>

<style scoped></style>
