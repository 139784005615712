<template>
        <header class="shadow-sm bg-white px-6 py-3 sm:py-6 rounded-lg">
        <nav class="flex justify-between container mx-auto">
          <NuxtLink class="text-lg sm:text-xl text-slate-500 font-bold" to="/"><img class="h-8 sm:h-12" src="/assets/img/ri-logo.svg" alt=""></NuxtLink>
          <div class="flex space-x-1 sm:space-x-4 items-center">
            <div class="btn"><NuxtLink to="/">Home</NuxtLink></div>
            <div class="btn"><NuxtLink class="" to="/property">Property</NuxtLink></div>
          </div>
        </nav>
      </header>
</template>

<script setup>

</script>

<style scoped>

</style>